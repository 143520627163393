import request from '@/utils/request'

export function exhibitorList(params) {
    return request({
        url: '/api/exhibitor/list',
        method: 'post',
        params
    })
}
export function getCloudNoticeListData(params) {
    return request({
        url: '/api/basics_set_up/get_info',
        method: 'post',
        params
    })
}
export function journalismList(params) {
    return request({
        url: '/api/getNewsList',
        method: 'get',
        params
    })
}
export function getCultureList(params) {
    return request({
        url: '/api/getCultureList',
        method: 'get',
        params
    })
}
export function getCultureDetails(params) {
    return request({
        url: '/api/getCultureDetails',
        method: 'get',
        params
    })
}
export function getNewsDetails(params) {
    return request({
        url: '/api/getNewsDetails',
        method: 'get',
        params
    })
}
export function exhibitorInfo(params) {
    return request({
        url: '/api/exhibitor/info',
        method: 'get',
        params
    })
}
export function exhibitorCate(params) {
    return request({
        url: '/api/exhibitor/cate',
        method: 'post',
        params
    })
}
export function boothCreateAdd(params) {
    return request({
        url: '/api/booth/create',
        method: 'post',
        params
    })
}
export function boothList(params) {
    return request({
        url: '/api/booth/list',
        method: 'post',
        params
    })
}
export function hanerLine(params) {
    return request({
        url: '/api/notice/get_info',
        method: 'post',
        params
    })
}